const brandComposable = useBrandsComposable();

export const hostS3 = ["development", "staging"].includes(environment)
  ? "https://s3.eu-west-3.amazonaws.com/resources-local-dev/"
  : brandComposable.getClientBrandConfig().urlS3;

export function downloadBuffer(buffer: any, fileName: string, type?: any) {
  let blobFile = null;
  const link = document.createElement("a");

  if (type) {
    blobFile = new Blob([buffer], { type });
  }

  link.href =
    blobFile === null ? window.URL.createObjectURL(buffer) : window.URL.createObjectURL(blobFile);

  link.download = fileName;
  link.click();
}
